import {
  ShouldShowItems,
  ShouldShowMedian,
  ShouldShowSpecialty,
  TrainingDuration,
  UserOptions,
} from '../domain/entities/UserOptions';
import {
  LimitedTimeHttp,
  ShowItemHttp,
  ShowMedianHttp,
  ShowSpecialityHttp,
  UserAccountHttpPatchBody,
  UserAccountHttpResponse,
} from './types';

export const mapUserOptionsHttpToDomain = (
  httpUserOptions: UserAccountHttpResponse
): UserOptions => {
  try {
    return {
      firstName: httpUserOptions.first_name,
      lastName: httpUserOptions.last_name,
      promotion: httpUserOptions.promotion,
      university: httpUserOptions.university,
      trainingDuration:
        mapTrainingDurationHttpToDomain[httpUserOptions.limited_time],
      shouldRandomizeTrainingOrder: httpUserOptions.randomized_training_order,
      shouldShowSpecialty:
        mapShowSpecialtyHttpToDomain[httpUserOptions.show_specialty],
      shouldShowItems: mapShowItemsHttpToDomain[httpUserOptions.show_item],
      shouldShowMedian: mapShowMedianHttpToDomain[httpUserOptions.show_median],
      shouldShowCorrectionCommentary: httpUserOptions.show_comment,
      shouldUseExplicitCorrectionDisplay:
        httpUserOptions.correction_explicit_display,
      email: httpUserOptions.email,
      isUserPremium: httpUserOptions.premium,
      endPremium: httpUserOptions.end_premium,
      flashMaxNew: httpUserOptions.flash_max_new,
      flashMaxReview: httpUserOptions.flash_max_review,
      newsletter: httpUserOptions.newsletter,
      needs_newsletter_prompt: httpUserOptions.needs_newsletter_prompt,
    };
  } catch {
    throw new Error(
      "Error in user options mapping, unexpected response from '/auth/user'"
    );
  }
};

export const mapPartialUserOptionsDomainToHttp = (
  userOptions: Partial<UserOptions>
): UserAccountHttpPatchBody => {
  return {
    first_name: userOptions.firstName,
    last_name: userOptions.lastName,
    promotion: userOptions.promotion,
    university: userOptions.university,
    limited_time: userOptions.trainingDuration
      ? mapTrainingDurationDomainToHttp[userOptions.trainingDuration]
      : undefined,
    randomized_training_order: userOptions.shouldRandomizeTrainingOrder,
    show_specialty: userOptions.shouldShowSpecialty
      ? mapShowSpecialtyDomainToHttp[userOptions.shouldShowSpecialty]
      : undefined,
    show_item: userOptions.shouldShowItems
      ? mapShowItemsDomainToHttp[userOptions.shouldShowItems]
      : undefined,
    show_comment: userOptions.shouldShowCorrectionCommentary,
    correction_explicit_display: userOptions.shouldUseExplicitCorrectionDisplay,
    show_median: userOptions.shouldShowMedian
      ? mapShowMedianDomainToHttp[userOptions.shouldShowMedian]
      : undefined,
    newsletter: userOptions.newsletter,
    needs_newsletter_prompt: userOptions.needs_newsletter_prompt,
  };
};

const mapTrainingDurationHttpToDomain: {
  [key in LimitedTimeHttp]: TrainingDuration;
} = {
  normal: 'normal',
  extra_time: 'extraTime',
  illimited: 'illimited',
};
const mapShowSpecialtyHttpToDomain: {
  [key in ShowSpecialityHttp]: ShouldShowSpecialty;
} = {
  always: 'always',
  never: 'never',
  only_done: 'whenDone',
};
const mapShowItemsHttpToDomain: { [key in ShowItemHttp]: ShouldShowItems } = {
  always: 'always',
  never: 'never',
  only_done: 'whenDone',
};
const mapShowMedianHttpToDomain: { [key in ShowMedianHttp]: ShouldShowMedian } =
  {
    always: 'always',
    never: 'never',
    only_done: 'whenDone',
  };

const mapTrainingDurationDomainToHttp: {
  [key in TrainingDuration]: LimitedTimeHttp;
} = {
  normal: 'normal',
  extraTime: 'extra_time',
  illimited: 'illimited',
};
const mapShowSpecialtyDomainToHttp: {
  [key in ShouldShowSpecialty]: ShowSpecialityHttp;
} = {
  always: 'always',
  never: 'never',
  whenDone: 'only_done',
};
const mapShowItemsDomainToHttp: { [key in ShouldShowItems]: ShowItemHttp } = {
  always: 'always',
  never: 'never',
  whenDone: 'only_done',
};
const mapShowMedianDomainToHttp: { [key in ShouldShowMedian]: ShowMedianHttp } =
  {
    always: 'always',
    never: 'never',
    whenDone: 'only_done',
  };
