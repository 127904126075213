import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { APIError } from 'auth/authentifiedHttpCalls';
import { routes } from 'routing/constants';
import { loginErrors } from 'auth/errors';
import { isProtectedRouteRoutingState } from 'routing/types';

import { UnverifiedEmailToast } from './components/UnverifiedEmailToast';
import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import { isLoggedIn, login } from 'auth/loginFunctions';

interface LoginFormInputs {
  email: string;
  password: string;
}

const loginFormSchema = yup.object({
  email: yup.string().required('Veuillez rentrer un email'),
  password: yup.string().required('Veuillez rentrer un mot de passe'),
});

export const useLoginForm = () => {
  return useForm<LoginFormInputs>({
    resolver: yupResolver(loginFormSchema),
  });
};

export const useOnLoginFormSubmit = ({
  onFetch,
  afterFetch,
}: {
  onFetch: () => void;
  afterFetch: () => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onLoginFormSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
    onFetch();

    try {
      await login(data.email, data.password);
      if (isLoggedIn()) {
        const routingState = location.state;
        if (isProtectedRouteRoutingState(routingState)) {
          navigate(routingState.from);
        } else {
          navigate(routes.HOME);
        }
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        const errors = (error as APIError).errorMessages;

        if (errors?.includes(loginErrors.EMAIL_NOT_VERIFIED)) {
          toast.error(<UnverifiedEmailToast email={data.email} />);
          return;
        }

        if (errors?.includes(loginErrors.INVALID_CREDENTIALS)) {
          toast.error('Couple d’identifiants invalide, veuillez réessayer');
          return;
        }

        if (error.message) {
          toast.error(error.message);
          return;
        }

        toast.error(
          "Une erreur inconnue s'est produite. Veuillez réessayer plus tard."
        );
      }
      reportErrorToSentry(error);
    } finally {
      afterFetch();
    }
  };

  return onLoginFormSubmit;
};
